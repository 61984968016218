import { api } from "../../services/httpclient";
import { apiFile } from "../../services/httpclient";

const state = {
  new_notifications: [],
  old_notifications: [],
  encrypt: {},
};

const getters = {
  GET_NEW_NOTIFICATION: (state) => state.new_notifications,
  GET_OLD_NOTIFICATION: (state) => state.old_notifications,
  HAS_NOTIFICATIONS: (state) =>
    state.new_notifications.length > 0 ? true : false,
  GET_KEYS: (state) => state.encrypt,
};

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.new_notifications = payload.filter(
      (notification) => notification.status == 1
    );
    state.old_notifications = payload.filter(
      (notification) => notification.status == 0
    );
  },

  SEND_ENCRYPT(state, payload) {
    state.encrypt = {
      data_encrypt: payload.data_encrypt,
      public_key: payload.public_key,
    };
  },

  SET_UPDATE_NOTIFICATION(state, payload) {
    const indexUpdate = state.new_notifications.findIndex(
      (index) => index.id == payload.data.id
    );

    if (indexUpdate > -1) {
      state.new_notifications.splice(indexUpdate, 1);
    }

    state.old_notifications.push(payload.data);
  },
};

const actions = {
  async UPDATE_PROFILE({ commit }, payload) {
    try {
      const response = await api.post(`api/v1/auth/update`, payload);
      commit("auth/SET_AUTH_USER", response.data.data, { root: true });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async PLAN_SERVICES() {
    try {
      const response = await api.get(`api/v1/plan`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_MODULES() {
    try {
      const response = await api.get(`api/v1/module`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async COMPLETE_MODULES() {
    try {
      const response = await api.get(`api/v1/complete_modules`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_LEVELS(_, payload) {
    try {
      const response = await api.get(
        `api/v1/list_module/level?module_id=${payload.module_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_MENU(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_module?module_id=${payload.module_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_DATA(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_level_module?module_id=${payload.module_id}&level=${payload.level}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_DATA_MY_WAY() {
    try {
      const response = await api.get(`api/v1/content_level_module/user`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_WATCHED_CONTENT() {
    try {
      const response = await api.get(
        `api/v1/content_level_module/watchedContent`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_BY_CODE_MODULE(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_level_module/contentByModuleId/${payload.module_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_PENDING_CONTENT() {
    try {
      const response = await api.get(
        `api/v1/content_level_module/pendingContent`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_APPOINTMENTS() {
    try {
      const response = await api.get(
        `api/v1/content_level_module/appointments`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_CONTENT_FAVORITE(_, payload) {
    try {
      const response = await api.post(`api/v1/content_favorite`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async MODULE_PERCENTAGE(_, payload) {
    try {
      const response = await api.post(
        `api/v1/path/user_percentage_module`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LEVEL_PERCENTAGE(_, payload) {
    try {
      const response = await api.post(
        `api/v1/path/user_percentage_path`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_MODULE_LEVEL({ commit }, payload) {
    try {
      const response = await api.post(`api/v1/module/update_user`, payload);
      commit("auth/SET_AUTH_USER", response.data.data, { root: true });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async VERIFY_PAY(_, payload) {
    try {
      const response = await api.post(`api/v1/plan_user/verify`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SHOW_CONTENT(_, payload) {
    // await console.log(
    //   payload,
    //   "Se comenrto este codigo para poder mantener las pildoras - PENDING"
    // );
    // return;
    try {
      const response = await api.post(`api/v1/content_user/time`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_NOTIFICATIONS({ commit }, payload) {
    try {
      const response = await api.get(`api/v1/notification`, payload);
      commit("SET_NOTIFICATIONS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PROFILE_PICTURE({ commit }, payload) {
    try {
      const response = await apiFile.post(`api/v1/auth/update_image`, payload);
      commit("auth/SET_AUTH_USER", response.data.data, { root: true });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async ENCRYPT_CONTENT({ commit }, payload) {
    try {
      const response = await apiFile.post(
        `api/v1/content_user/content_encrypt`,
        payload
      );
      commit("SEND_ENCRYPT", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DESECRYPT_CONTENT(_, payload) {
    try {
      const response = await apiFile.post(`api/v1/content_user/token`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async CREATE_LESSONS_QUESTION(_, payload) {
    try {
      // {
      //   "lesson_id": 2393,
      //   "course_id": 194,
      //   "question": "OK OK",
      //   "is_public": true
      // }
      const response = await apiFile.post(`api/v1/content_user/lessons_question`, payload);
      // // Example response
      // {
      //   "lesson_id": 2393,
      //   "course_id": 194,
      //   "question": "OK OK",
      //   "is_public": true,
      //   "whatsapp_account_id": 1565,
      //   "schedule_id": 57,
      //   "updated_at": "2025-03-27T23:56:55.000000Z",
      //   "created_at": "2025-03-27T23:56:55.000000Z",
      //   "id": 3
      // }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_LESSONS_QUESTION(_, payload) {
    try {
      const owner = payload.is_public ? 'any': 'me';
      const response = await api.get(`api/v1/content_user/lessons_question?filter[lesson_id]=${payload.lesson_id}&filter[owner]=${owner}&include=lesson,learner,attachments,responses.user,responses.attachments`);

      // const response = await apiFile.get(`api/v1/content_user/lessons_question?filter[lesson_id]=${payload.lesson_id}&filter[is_public]=${payload.is_public}&include=learner,responses.user`);
      // Example response LIST_LESSONS_QUESTION
      //   {
      //     "current_page": 1,
      //     "data": [
      //         {
      //             "id": 2,
      //             "lesson_id": 2393,
      //             "whatsapp_account_id": 1565,
      //             "schedule_id": 57,
      //             "course_id": 194,
      //             "question": "Tengo varias dudas, que debo enviar ?",
      //             "is_public": 0,
      //             "created_at": "2025-03-27T23:16:18.000000Z",
      //             "updated_at": "2025-03-27T23:16:23.000000Z",
      //             "learner": {
      //                 "id": 1565,
      //                 "name": "Eduardo",
      //                 "last_name": "Juarez",
      //                 "full_name": "Eduardo Juarez"
      //             },
      //             "responses": [
      //                 {
      //                     "id": 2,
      //                     "question_id": 2,
      //                     "user_id": 108,
      //                     "response": "Solo suba el archivo !!1 ",
      //                     "created_at": "2025-03-27T23:35:06.000000Z",
      //                     "updated_at": "2025-03-27T23:35:09.000000Z",
      //                     "user": {
      //                         "id": 108,
      //                         "name": "Carlos Santizo",
      //                         "email": "sales@pilsa.io",
      //                         "profile_photo_url": "https://ui-avatars.com/api/?name=Carlos+Santizo&color=7F9CF5&background=EBF4FF"
      //                     }
      //                 }
      //             ]
      //         }
      //     ],
      //     "first_page_url": "http://localhost:8000/api/v1/content_user/lessons_question?page=1",
      //     "from": 1,
      //     "last_page": 1,
      //     "last_page_url": "http://localhost:8000/api/v1/content_user/lessons_question?page=1",
      //     "links": [
      //         {
      //             "url": null,
      //             "label": "&laquo; Anterior",
      //             "active": false
      //         },
      //         {
      //             "url": "http://localhost:8000/api/v1/content_user/lessons_question?page=1",
      //             "label": "1",
      //             "active": true
      //         },
      //         {
      //             "url": null,
      //             "label": "Siguiente &raquo;",
      //             "active": false
      //         }
      //     ],
      //     "next_page_url": null,
      //     "path": "http://localhost:8000/api/v1/content_user/lessons_question",
      //     "per_page": 10,
      //     "prev_page_url": null,
      //     "to": 1,
      //     "total": 1
      // }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SEND_RESPONSE_PAY({ commit, dispatch }, payload) {
    try {
      const response = await apiFile.post(`api/v1/plan_user`, payload);
      commit("auth/SET_TOKEN", response.data.data.token, { root: true });
      commit("auth/SET_AUTH_USER", response.data.data.user, { root: true });
      dispatch("GET_NOTIFICATIONS");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async NEW_SEND_RESPONSE_PAY({ commit, dispatch }, payload) {
    try {
      const response = await apiFile.post(`api/v1/plan_user/store`, payload);
      commit("auth/SET_TOKEN", response.data.data.token, { root: true });
      commit("auth/SET_AUTH_USER", response.data.data.user, { root: true });
      dispatch("GET_NOTIFICATIONS");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_NOTIFICATION({ commit }, payload) {
    try {
      const response = await api.put(
        `api/v1/notification/${payload.id}`,
        payload
      );
      const request = {
        data: response.data.data[0],
        status: payload.status,
      };

      commit("SET_UPDATE_NOTIFICATION", request);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_FAVORITES(_, payload) {
    try {
      const response = await api.get(
        `api/v1/content_favorite?module_id=${payload.module_id}&level=${payload.level}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const profile = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
